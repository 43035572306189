<template>
    <!-- 套餐类别 -->
    <div class="materia-type">
        <div class="materia-top">
                <el-button size="small" type="primary" @click="addClick">新增套餐类别</el-button>
        </div>
        <div class="materia-content">
            <div class="content-box" v-for="(item,index) in materiaList" :key="index">
                <div class="box-materia">
                    <div class="materia-name" v-bind:title="item.target">{{item.target}}</div>
                    <div class="materia-line"></div>
                    <div class="pic-box">
                        <img src="./img/edit.png" class="picture picbtn" @click="deitData(item)" />
                        <img src="./img/delete.png" class="picture" @click="deleteData(item)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" v-if="listQuery.total>=15">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.current"
                :page-size="listQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="listQuery.total"
            >
            </el-pagination>
        </div>
        <!-- 新增弹框 -->
        <el-dialog
            :title="titleName+'套餐类别'"
            :visible.sync="dialogVisible"
            width="480px"
            :showClose="false"
            :close-on-click-modal="false"
            >
            <el-form :model="addData" size="small" label-width="100px" ref="addData">
                <el-form-item label="套餐类别"
                prop="target"
                :rules="{required: true, message: '请输入套餐类别', trigger: 'blur'}"
                >
                    <el-input v-model="addData.target" style="width:280px"></el-input>
                </el-form-item>
            </el-form>
            <div class="footer-btn">
                <el-button size="small" @click="cancelClick">取 消</el-button>
                <el-button size="small" type="primary" @click="addSure" v-if="titleName=='新增'">确 定</el-button>
                <el-button size="small" type="primary" @click="editSure" v-else>确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
    
<script>
import { categorylist,categoryadd,categoryedit,remove } from "../../api/index";
export default {
    data() {
        return {
            dialogVisible:false,
            titleName:"",
            addData:{
                target:"",//套餐名称
            },
            listQuery:{
                current: 1,
                size: 10,
                total: 0,
                type:1,//套餐类别
            },
            materiaList:[],
        }
    },
    mounted() {
        this.getTableDataList()
    },
    methods:{
        getTableDataList() {
            let data = {
                ...this.listQuery
            }
            categorylist(data).then(res=>{
            if(res.code==1) {
                this.materiaList = res.data.records || [];
                this.listQuery.total = res.data.total || 0
                }
            })
        },
        handleCurrentChange() {},
        addClick() {
            this.titleName = '新增';
            this.addData = {target:""}
            this.dialogVisible = true
        },
        addSure() {
            let data = {
                ...this.addData,
                type:1,
            }
            this.$refs.addData.validate((valid)=>{
                if(valid) {
                    categoryadd(data).then(res=>{
                        if(res.code==1) {
                            this.$message.success("新增成功！");
                            this.getTableDataList()
                            this.dialogVisible = false;
                            this.$refs.addData.resetFields();
                            this.addData = {
                                target:""
                            }
                        }
                    })
                }
            })
        },

    // 删除
    deleteData(val) {
        console.log(val.id,"ffffff")
        this.$confirm('确定删除此套餐类别?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            remove({id:val.id}).then(res=>{
                if(res.code==1) {
                    this.$message.success("删除成功！");
                    this.getTableDataList()
                }
            })
        }).catch(() => {});
    },
    // 取消
    cancelClick() {
        this.dialogVisible = false;
        this.addData.typeName = "";
    },
    // 编辑
    deitData(val) {
        this.titleName = '编辑'
        this.dialogVisible = true
        this.addData = {...val}
    },
    editSure() {
        let data = {
                ...this.addData,
                type:1,
            }
            this.$refs.addData.validate((valid)=>{
                if(valid) {
                categoryedit(data).then(res=>{
                    if(res.code==1) {
                        this.$message.success("编辑成功！");
                        this.getTableDataList()
                        this.dialogVisible = false;
                        this.$refs.addData.resetFields();
                        this.addData = {
                            target:""
                        }
                    }
                })
                }
            })
    },
    }  
}
</script>

<style lang="less" scoped>
    .footer-btn{
        display: flex;
        justify-content: flex-end;
    }
    .materia-top{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
.materia-type {
    margin-left:20px;
    .right-btn {
        display: flex;
        .import-car {
            background: #E7FBF4;
            color: #15D393;
            border: 1px solid #E7FBF4;
            margin-right:20px
        }
    }
    /deep/.el-upload {
        border: 0;
    }
    .el-form-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    .materia-content {
        display: flex;
        flex-wrap: wrap;
        .content-box {
            .box-materia {
                width: 220px;
                height: 90px;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                margin:0 30px 30px 0;
                display: flex;
                align-items: center;
                .materia-name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #666666;
                    margin:0 20px;
                    width: 120px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .materia-line {
                    width: 1px;
                    height: 50px;
                    background: #CCCCCC;
                }
                .pic-box {
                    margin:0 25px;
                    .picture {
                        width: 17px;
                        height: 17px;
                        display: block;
                        cursor: pointer;
                    }
                    .picbtn {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }

}
</style>